
import { computed, defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheGiftList from '@/modules/membership/components/TheGiftList.vue'
import { Gifts, readGifts } from '@/modules/membership/member.api'
import state from '@/state'
import { mul, sub } from 'essential/tools/math'
import Icon from '@/components/Icon.vue'
import { YesOrNo } from '@/types'
import { refreshAccount } from '@/modules/app/app'

export default defineComponent({
  name: 'PointsStore',
  components: { TheGiftList, PageWithHeader, Icon },
  setup () {
    const giftList = ref<Array<Gifts> | null>(null)

    refreshAccount()

    readGifts({ page: 1, pageCount: 10 })
      .then(resp => giftList.value = resp as Array<Gifts>)

    return {
      giftList,
      isPrime: computed(() => state.prime.member === YesOrNo.YES),
      levelModel: computed(() => state.account.levelModel),
      rebateRate: computed(() => mul(sub(1, state.account.levelModel.rebateRate), 100)),
    }
  },
})
