
import { defineComponent } from 'vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import Button from '@/components/Button.vue'
import { Gifts } from '@/modules/membership/member.api'
import { openDialog } from '@/components/popup/popup'
import ExchangeDialog from '@/modules/membership/components/ExchangeDialog.vue'
import TheGiftItem from '@/modules/membership/components/TheGiftItem.vue'

export default defineComponent({
  name: 'TheGiftList',
  components: { TheGiftItem, Button, Holder, List },
  props: {
    list: {
      type: Array,
    },
  },
  setup () {
    const buy = (data: Gifts) => {
      openDialog(ExchangeDialog, { gift: data })
    }

    return {
      buy,
    }
  },
})

