
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ExchangeResultDialog',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
