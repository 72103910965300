
import Button from '@/components/Button.vue'
import { refreshAccount } from '@/modules/app/app'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, ref, shallowRef } from 'vue'
import useRequest from '@/hooks/useRequest'
import { exchange } from '@/modules/membership/member.api'
import { openDialog } from '@/components/popup/popup'
import ExchangeResultDialog from '@/modules/membership/components/ExchangeResultDialog.vue'
import TheGiftItem from '@/modules/membership/components/TheGiftItem.vue'
import Icon from '@/components/Icon.vue'
import { isPrime } from '@/state'

export default defineComponent({
  name: 'ExchangeDialog',
  components: { Icon, TheGiftItem, Button, DialogTemplate },
  emits: ['close'],
  props: {
    gift: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const numValue = ref(1)
    const [commit, progress] = useRequest(exchange)
    const basePoint = isPrime() ? props.gift.discountPoints : props.gift.poins
    const changePoints = shallowRef(isPrime() ? props.gift.discountPoints : props.gift.poins)

    const change = (dir: number) => {
      numValue.value = dir > 0 ? Number(numValue.value) + 1 : Number(numValue.value) - 1
      changePoints.value = basePoint * numValue.value
    }

    return {
      submit () {
        commit({
          giftId: props.gift.giftId,
          giftNum: numValue.value,
        }).then(() => {
          refreshAccount()
          ctx.emit('close', () => {
            openDialog(ExchangeResultDialog, { order: { giftNum: numValue.value, giftName: props.gift.giftName } })
          })
        })
      },
      progress,
      numValue,
      change,
      changePoints,
    }
  },
})
