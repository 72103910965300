
import { computed, defineComponent } from 'vue'
import state from '@/state'
import { mul } from 'essential/tools/math'
import Pic from '@/components/Pic.vue'
import { YesOrNo } from '@/types'

export default defineComponent({
  name: 'TheGiftItem',
  components: { Pic },
  props: {
    data: {
      type: Object,
      required: true,
    },
    noBtn: Boolean,
  },
  setup (props) {
    return {
      isPrime: computed(() => state.prime.member === YesOrNo.YES),
      rebateRate: computed(() => state.account.levelModel.rebateRate),
      finalPoints: computed(() => mul(props.data.poins, state.account.levelModel.rebateRate)),
    }
  },
})

